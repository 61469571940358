import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import ScrollSection from "../components/scroll-section/scroll-section";

function NotFoundPage() {
  return (
    <Layout>
      <Seo
        type="page"
        content={{
          title: "NOT FOUND",
          seoDescription: "Page not found",
        }}
      />
      <ScrollSection>
        <div className="mt-5 mx-auto mb-24 page-content">
          <h1 className="section-headline">NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          <p>
            Go back to the <Link to="/">Homepage</Link> and try again!
          </p>
        </div>
      </ScrollSection>
    </Layout>
  );
}

export default NotFoundPage;
